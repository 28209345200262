<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-item-card">
        <div :class="'tag processing' " v-if="getTransactionStatus(landInfo.transactionStatus)">
           {{ getTransactionStatus(landInfo.transactionStatus) }}

        </div>
        <img :src="image" class="card-image" @click="toDetail" style="cursor: pointer;"/>
        <div class="card-content" style="padding: 14px">
            <!-- <h4><RouterLink to="/tradingServices">{{ landInfo.title }}</RouterLink></h4> -->
            <h4 @click="toDetail"  style="cursor: pointer;">
                {{ landInfo.title }}
            </h4>
            <div class="card-info-item">
                <i class="ti ti-dimensions"></i>
                <span>面积：</span>
                <span>{{ landInfo.landSize }} 平方米</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-map-pin"></i>
                <span>所属行政区1：</span>
                <span>{{ getXzqName(landInfo.regionId) }}</span>
            </div>
            <div class="card-info-item">
                <i class="ti ti-home-hand"></i>
                <span>土地用途：</span>
                <span style="overflow: hidden; white-space: nowrap;text-overflow:ellipsis;">{{ getLandPurposeName(landInfo.landPurpose) }} <!-- {{getLandPurposeItemName(landInfo.landPurpose, landInfo.subLandPurpose)}}--></span>

            </div>
          <div>
            <img v-if="landInfo.id==160" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png">
            <img v-if="landInfo.id==311" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/26c023affae24cab9384c2ada4f38694.png">
            <img v-if="landInfo.id==161" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/9ca208ec48d64d5fa17ad952696d2e60.png">
            <img v-if="landInfo.id==167" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/8377cf5554ee4d8e90914608f27a6e84.png">
            <img v-if="landInfo.id==168" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/api/admin/sys-file/dev-1304594149/9ec36c22b58f4398b6a0064063cf5157.png">
            <img v-if="landInfo.id==293" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/d7b50c0297fd46679bb02c46f78188ab.png">
            <img v-if="landInfo.id==294" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/2e8ea2aef338495bad7d7c21da2564e3.png ">
            <img v-if="landInfo.id==204" style="width: 70px;float: right; margin-top: -70px" src="https://land.dytudi.com.cn/admin/sys-file/dev-1304594149/3fa3ac4116fd4d96a53b5b793ec374c1.png">
          </div>
          <div style="clear: both">

          </div>
            <div class="card-info-item">
                <i class="ti ti-clock-hour-9"></i>
                <span>发布时间：</span>
                <span>{{ landInfo.createTime }}</span>
            </div>

        </div>
    </el-card>
</template>
<script>

import {useDict} from "@/hooks/dict";
import {computed} from "vue";

const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};


const transaction_status = {
    'processing': '正在交易',
    'success': '已成交'
}

export default {
    name: "LandItemCard",
    data() {
        return {};
    },
    props: {
      tabName:{
        type:String,
        default:''
      },
        landInfo: {
            type: Object,
            default: () => ({
                id: '',
                landPics: "",
                title: "name",
                landSize: 99.99,
                regionId: "武侯区",
                landPurpose: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
            }),
        }
    },
    components: {  },
    computed: {
        image() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",")[0] +'?w=300': "";
        },
       demandPurposeItemOptionsComputed (demandPurpose) {
        const options = useDict(demandPurpose);
        return options[demandPurpose].value;
      },

    },
    methods: {
        getXzqName(id) {
            return xzq[id];
        },
        getLandPurposeName(id) {
          let land_purposes = useDict('demand_purpose');
          let tmp = '';
          land_purposes.demand_purpose.value.forEach((item) => {
            if (item.value === id) {
              tmp =  item.label;
            }
          })
          return tmp;
        },
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
      getTransactionStatus(id){
            return transaction_status[id];
        },
        toDetail(){
            if(this.landInfo.auctionInfo &&  this.landInfo.transactionWay === "listing"){
                this.$router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
            }
           else if( this.landInfo.transactionWay === "auction" && this.landInfo.auctionInfo){
             this.$router.push("/land/auction/"+this.landInfo.id);
           }
            else{
                this.$router.push("/land/detail/"+this.landInfo.id);
            }
        }
    },
};
</script>
<style lang="scss" scoped></style>
